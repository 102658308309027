import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  Link,
} from "@chakra-ui/react";

export const JunkRemovalServices = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Expert Junk Removal Services in Portsmouth, VA
        </Heading>
        <Text>
          Dealing with unwanted clutter? Portsmouth Dumpster Rental offers
          comprehensive junk removal services in Portsmouth, VA, catering to
          both residential and commercial needs. Whether it's old furniture,
          appliances, or general clutter, our team is equipped to handle it all
          efficiently and responsibly.
        </Text>
        <Text>
          Our junk removal service is more than just disposing of unwanted
          items; it's about providing a stress-free and eco-friendly solution to
          decluttering. We understand the challenges that come with large-scale
          cleanouts and are here to simplify the process, ensuring your space is
          left clean and clear.
        </Text>

        <Heading as={"h3"} size={"lg"}>
          Why Choose Our Junk Removal Services?
        </Heading>
        <UnorderedList spacing={3}>
          <ListItem>
            Professional and Prompt Service: Experience timely and efficient
            junk removal with our expert team.
          </ListItem>
          <ListItem>
            Eco-friendly Disposal: We prioritize recycling and responsible
            disposal, reducing environmental impact.
          </ListItem>
          <ListItem>
            Versatile Capabilities: From residential to commercial spaces, no
            job is too big or small for us.
          </ListItem>
          <ListItem>
            Customized Solutions: We tailor our services to meet your specific
            junk removal needs and schedule.
          </ListItem>
        </UnorderedList>
        <Text>
          Our commitment to customer satisfaction and environmental
          responsibility sets us apart in Portsmouth. We go the extra mile to
          ensure that your junk removal experience is seamless and eco-friendly.
        </Text>

        <Heading as={"h3"} size={"lg"}>
          Our Junk Removal Process
        </Heading>
        <Text>
          Embark on a hassle-free junk removal experience with us, as we guide
          you through each step. Our process commences with a consultation to
          understand your needs, followed by scheduling a convenient pickup
          time. Our adept team arrives ready to manage all heavy lifting,
          sorting, and transportation of your junk. We prioritize responsible
          disposal, emphasizing recycling and donation whenever possible to
          ensure a comprehensive and environmentally conscious approach to junk
          removal.
        </Text>

        <Heading as={"h3"} size={"lg"}>
          Contact Us for Your Junk Removal Needs in Portsmouth
        </Heading>
        <Text>
          Ready to clear out the clutter?{" "}
          <Link href={"tel:7577045492"}>Call us at 757-704-5492</Link> to
          discuss your junk removal needs in Portsmouth, VA. Our team is ready
          to provide you with a free estimate and assist you in reclaiming your
          space.
        </Text>

        <Text>
          In addition to junk removal, explore our other services like{" "}
          <Link href={"/same-day-dumpster-rentals"}>
            Same-Day Dumpster Rentals
          </Link>
          , <Link href={"/event-waste-management"}>Event Waste Management</Link>
          , and{" "}
          <Link href={"/roll-off-dumpster-rentals"}>
            Roll-Off Dumpster Rentals
          </Link>{" "}
          to cover all your waste management requirements. Trust Portsmouth
          Dumpster Rental for all your disposal needs.
        </Text>
      </Stack>
    </Box>
  );
};
