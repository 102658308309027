import {createBrowserRouter} from 'react-router-dom';
import HomeLayout from '../Layouts/HomeLayout';
import About from '../Pages/About';
import ContactPage from '../Pages/ContactPage';
import Home01 from '../Pages/Homes/Home01';
import Services01 from '../Pages/Services/Services01';
import ErrorPage from '../Pages/ErrorPage/ErrorPage';
import Blog from "../Pages/Blog";
import ResidentialDumpster from "../Pages/Services/ResidentialDumpster";
import WasteManagement from "../Pages/Services/WasteManagement";
import RollOff from "../Pages/Services/RollOff";
import CommercialDumpster from "../Pages/Services/CommercialDumpster";
import Careers from "../Pages/Careers";
import React from "react";
import {BlogPost} from "../Layouts/BlogPost";
import SameDayDumpster from "../Pages/Services/SameDayDumpster";
import EventWasteManagement from "../Pages/Services/EventWasteManagement";
import JunkRemovalServices from "../Pages/Services/JunkRemovalServices";
import LongTermDumpster from "../Pages/Services/LongTermDumpster";
import DirectoriesOnTheWeb from "../Pages/DirectoriesOnTheWeb";


export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout/>,
        children: [
            {
                path: '/',
                element: <Home01/>
            },
            {
                path: '/blog',
                element: <Blog/>
            },
            {
                path: '/services',
                element: <Services01/>
            },
            {
                path: '/residential-dumpster-rentals',
                element: <ResidentialDumpster/>
            },
            {
                path: '/commercial-dumpster-rentals',
                element: <CommercialDumpster/>
            },
            {
                path: '/roll-off-dumpster-rentals',
                element: <RollOff/>
            },
            {
                path: '/waste-management',
                element: <WasteManagement/>
            },
            {
                path: '/same-day-dumpster-rentals',
                element: <SameDayDumpster/>
            },
            {
                path: '/event-waste-management',
                element: <EventWasteManagement/>
            },
            {
                path: '/junk-removal-services',
                element: <JunkRemovalServices/>
            },
            {
                path: '/long-term-dumpster-rentals',
                element: <LongTermDumpster/>
            },
            {
                path: '/about',
                element: <About/>
            },
            {
                path: '/careers',
                element: <Careers/>
            },
            {
                path: '/contact',
                element: <ContactPage/>
            },
            {
                path: '/blog/:id',
                element: <BlogPost/>
            },
            {
                path: '/directories-on-the-web',
                element: <DirectoriesOnTheWeb/>
            },
            {
                path: '*',
                element: <ErrorPage/>
            },
        ]
    }
])

