import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";

export const EventWasteManagement = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Event Waste Management Services in Portsmouth, VA</Heading>
                <Text>
                    Hosting an event in Portsmouth, VA? Ensure its success with professional waste management services
                    from Portsmouth Dumpster Rental. We specialize in providing comprehensive waste solutions tailored
                    to a variety of events, from large festivals to intimate gatherings. Our expertise ensures a clean,
                    safe, and environmentally responsible event experience.
                </Text>
                <Text>
                    Why is effective waste management crucial for your event? Proper waste handling not only maintains
                    the cleanliness and aesthetic appeal of your venue but also reflects your commitment to
                    sustainability and environmental responsibility. Whether it's a corporate event, wedding, community
                    festival, or concert, managing the waste efficiently is key to a successful and memorable event.
                </Text>

                <Heading as={'h3'} size={'lg'}>Our Comprehensive Event Waste Management Solutions</Heading>
                <UnorderedList spacing={3}>
                    <ListItem>Efficient Trash Disposal: Our dumpsters are perfect for handling general waste, food
                        waste, and other disposables generated at events.</ListItem>
                    <ListItem>Recycling Services: We promote eco-friendly practices by offering recycling options for
                        paper, plastics, glass, and more.</ListItem>
                    <ListItem>Customizable Dumpster Sizes: Catering to events of all scales, we provide various dumpster
                        sizes to match your specific waste volume.</ListItem>
                    <ListItem>Timely Delivery and Pickup: Coordination is key in event planning. We ensure punctual
                        delivery and pickup to align with your event schedule.</ListItem>
                </UnorderedList>
                <Text>
                    Opting for Portsmouth Dumpster Rental means choosing peace of mind. Our professional team
                    understands the unique challenges and requirements of event waste management. We work closely with
                    you to plan and execute waste disposal that minimizes environmental impact and maximizes efficiency.
                </Text>

                <Heading as={'h3'} size={'lg'}>Why Choose Portsmouth Dumpster Rental for Your Event?</Heading>
                <UnorderedList spacing={3}>
                    <ListItem><strong>Local Expertise:</strong> Being based in Portsmouth, VA, we have a deep
                        understanding of the local regulations and best practices for waste management at
                        events.</ListItem>
                    <ListItem><strong>Sustainable Practices:</strong> We are committed to reducing the environmental
                        footprint of your event with our sustainable waste disposal methods.</ListItem>
                    <ListItem><strong>Customizable Solutions:</strong> Every event is unique, and so are its waste
                        management needs. We offer personalized service to meet your specific requirements.</ListItem>
                    <ListItem><strong>Reliable and Efficient:</strong> Our team is known for its reliability and
                        efficiency, ensuring your event's waste is managed seamlessly.</ListItem>
                </UnorderedList>
                <Text>
                    At Portsmouth Dumpster Rental, we don’t just provide dumpsters; we offer a complete waste management
                    solution. From initial consultation to post-event cleanup, we're with you every step of the way,
                    ensuring your event in Portsmouth runs smoothly and remains clean and green.
                </Text>

                <Heading as={'h3'} size={'lg'}>Contact Us Today for Your Event Waste Management Needs</Heading>
                <Text>
                    Ready to discuss your event’s waste management needs in Portsmouth? <Link
                    href={'tel:7577045492'}>Call us at 757-704-5492</Link> for a consultation. Our team is ready
                    to assist you in planning and executing the perfect waste management strategy for your event.
                </Text>

                <Text>
                    Looking for other waste management solutions? Explore our range of services, including <Link
                    href={'/residential-dumpster-rentals'}>Residential Dumpster Rentals</Link>, <Link
                    href={'/commercial-dumpster-rentals'}>Commercial Dumpster Rentals</Link>, and <Link
                    href={'/roll-off-dumpster-rentals'}>Roll-Off Dumpster Rentals</Link>. At Portsmouth Dumpster
                    Rental, we cover all your waste disposal needs with expertise and care.
                </Text>
            </Stack>
        </Box>
    )
}