export const posts = [
    {
        "id": "0",
        "title": "Efficient Waste Management with Portsmouth Dumpster Rental Services",
        "excerpt": "Discover how Portsmouth Dumpster Rental makes waste management efficient and hassle-free in Virginia.",
        "slug": "efficient-waste-management-portsmouth-dumpster-rental",
        "tags": ["Dumpster Rental", "Waste Management", "Virginia"],
        "metaDescription": "Explore the efficiency and convenience of using Portsmouth Dumpster Rental for your waste management needs in Virginia.",
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        "id": "1",
        "title": "Your Ultimate Guide to Residential Dumpster Rentals in Portsmouth",
        "excerpt": "Learn how residential dumpster rentals can simplify your home projects in Portsmouth, Virginia.",
        "slug": "residential-dumpster-rentals-portsmouth",
        "tags": ["Residential Dumpster", "Home Improvement", "Virginia"],
        "metaDescription": "Discover the benefits of residential dumpster rentals for your home projects in Portsmouth, Virginia.",
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        "id": "2",
        "title": "Commercial Dumpster Rentals: Enhancing Business Efficiency in Portsmouth",
        "excerpt": "Boost your business efficiency with our tailored commercial dumpster rental services in Portsmouth, Virginia.",
        "slug": "commercial-dumpster-rentals-portsmouth",
        "tags": ["Commercial Dumpster", "Business Efficiency", "Virginia"],
        "metaDescription": "Learn how our commercial dumpster rental services can enhance the efficiency of your business operations in Portsmouth, Virginia.",
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        "id": "3",
        "title": "Top Tips for Maximizing Your Dumpster Rental Experience in Portsmouth",
        "excerpt": "Get the most out of your dumpster rental with these essential tips for Portsmouth residents and businesses.",
        "slug": "maximizing-dumpster-rental-experience-portsmouth",
        "tags": ["Dumpster Rental Tips", "Efficiency", "Virginia"],
        "metaDescription": "Maximize the efficiency and effectiveness of your dumpster rental in Portsmouth with our expert tips.",
        lastModified: new Date('2023-12-03').toISOString()
    },
];
