import React from "react";
import { Heading, Stack, Text } from "@chakra-ui/react";

export const BlogPost1 = () => {
  return (
    <Stack spacing={3}>
      <Text>
        Efficient waste management is crucial for both residential and
        commercial projects. Portsmouth Dumpster Rental in Virginia offers a
        variety of services to make waste disposal effortless and
        environmentally friendly. Our focus is on providing convenient,
        cost-effective solutions for all your waste management needs.
      </Text>
      <Heading as={"h3"} size={"md"} fontWeight={"bold"}>
        Variety of Dumpster Sizes
      </Heading>
      <Text>
        Choose from our diverse range of dumpster sizes, starting from small
        10-yard options suitable for home cleanouts to large 40-yard dumpsters
        ideal for expansive commercial construction projects. Our experienced
        team is ready to assist you in selecting the perfect size tailored to
        your specific requirements. Whether it's a residential cleanup or a
        substantial commercial endeavor, we have the right dumpster size to meet
        your needs effectively.
      </Text>
      <Heading as={"h3"} size={"md"} fontWeight={"bold"}>
        Simple and Transparent Pricing
      </Heading>
      <Text>
        Experience transparent and budget-friendly dumpster rental with our
        straightforward pricing structure, free from hidden fees. Our
        all-inclusive cost covers delivery, pickup, and disposal, providing
        clarity and ensuring a hassle-free experience for our clients. Choose us
        for a reliable and upfront pricing model that aligns with your budgetary
        considerations.
      </Text>
      <Heading as={"h3"} size={"md"} fontWeight={"bold"}>
        Dedicated Customer Service
      </Heading>
      <Text>
        Count on our dedicated customer service team to ensure your satisfaction
        throughout the dumpster rental process. Whether you have questions or
        need guidance, we're here to assist you every step of the way. Choose
        Portsmouth Dumpster Rental for a seamless and customer-centric
        experience.
      </Text>
      <Heading as={"h3"} size={"md"} fontWeight={"bold"}>
        Eco-friendly Disposal
      </Heading>
      <Text>
        Our unwavering commitment to responsible waste management underscores
        our dedication to environmental stewardship. At Portsmouth Dumpster
        Rental, we prioritize and actively engage in recycling initiatives and
        eco-friendly disposal practices. By placing a strong emphasis on these
        sustainable methods, we aim to minimize our ecological footprint and
        contribute to the preservation of our environment. When you choose our
        services, you align with a mission that values not only effective waste
        management but also the promotion of practices that support a healthier
        and more sustainable planet. Trust us to be your partner in creating a
        positive impact through responsible waste disposal.
      </Text>
      <Text>
        In conclusion, Portsmouth Dumpster Rental is your go-to choice for
        efficient and responsible waste management solutions in Virginia.
      </Text>
    </Stack>
  );
};
