import React from 'react';
import PageHeader from '../Components/common/PageHeader';
import {SEO} from "../Components/SEO/SEO";
import {Box, Link, List, ListItem} from "@chakra-ui/react";

const urls = [
    "https://www.manta.com/c/m1w1fht/portsmouth-dumpster-rental",
    "https://www.hotfrog.com/company/479b47ac8fb2b308dbe2ee723b8ea2c9",
    "https://www.n49.com/biz/5790987/portsmouth-dumpster-rental/",
    "https://va-portsmouth.cataloxy.us/firms/portsmouthdumpsterrental.com.htm",
    "https://www.callupcontact.com/b/businessprofile/Portsmouth_Dumpster_Rental/8857748",
    "https://www.zeemaps.com/map?group=4879509&add=1",
    "http://www.askmap.net/location/6796849/united-states/portsmouth-dumpster-rental",
    "https://www.yplocal.com/business-professional-services/portsmouth-dumpster-rental",
    "https://www.adlocalpages.com/business-professional-services/portsmouth-dumpster-rental",
    "https://www.nextbizthing.com/business-support-20-supplies-20-services/portsmouth-dumpster-rental",
    "https://www.freelistingusa.com/listings/portsmouth-dumpster-rental",
    "http://www.travelful.net/location/5348534/united-states/portsmouth-dumpster-rental",
    "http://www.place123.net/place/portsmouth-dumpster-rental-.-united-states",
    "https://teleadreson.com/portsmouth-dumpster-rental,-aveRAGr3kQA.html",
    "https://www.cgmimm.com/professional-services/portsmouth-dumpster-rental",
    "http://where2go.com/binn/b_search.w2g?function=detail&type=power&listing_no=2125662&_UserReference=7F000001465172F3ACAC41D32C4B65992361",
    "https://trueen.com/business/listing/portsmouth-dumpster-rental/389488",
    "https://smallbusinessusa.com/listing/pdumpsterrental.html",
    "https://www.bizbangboom.com/professional-services/portsmouth-dumpster-rental",
    "https://www.nextbizmaker.com/professional-services/portsmouth-dumpster-rental",
    "https://www.announceamerica.com/b2b-1/portsmouth-dumpster-rental",
    "https://www.bizmakersamerica.org/professional-services/portsmouth-dumpster-rental",
    "https://www.youbiz.com/listing/pdumpsterrental.html",
    "https://www.bizmaker.org/business-services/portsmouth-dumpster-rental",
    "https://www.biztobiz.org/professional-services/portsmouth-dumpster-rental",
    "https://www.homify.com/projects/1207851/portsmouth-dumpster-rental",
    "https://www.earthmom.org/professional-services/portsmouth-dumpster-rental",
    "https://www.linkcentre.com/profile/pdumpsterrental/",
    "https://www.cleansway.com/business/portsmouth-dumpster-rental",
    "https://www.consultsdirect.com/consultant/portsmouth-dumpster-rental",
    "https://www.finditangeles.com/services/portsmouth-dumpster-rental",
    "https://flokii.com/businesses/view/118565/portsmouth-dumpster-rental",
    "https://i.imgur.com/UDb0BXB.png",
    "http://www.4mark.net/story/11132326/portsmouth-dumpster-rental",
    "https://www.anibookmark.com/business/portsmouth-dumpster-rental-bs183447.html",
    "https://www.ilistbusiness.com/listing/portsmouth-dumpster-rental/",
    "https://serverfault.com/users/1069473/portsmouth-dumpster-rental?tab=profile",
    "https://www.preferredprofessionals.com/professional-services/portsmouth-dumpster-rental",
    "https://superuser.com/users/1878348/portsmouth-dumpster-rental?tab=profile",
    "https://411freedirectory.com/listing/portsmouth-dumpster-rental-593179",
    "https://activdirectory.net/listing/portsmouth-dumpster-rental-805614",
    "https://www.thelululist.com/brown-and-black-owned-professional-services/portsmouth-dumpster-rental",
    "https://directory6.org/listing/portsmouth-dumpster-rental-647634",
    "https://stackoverflow.com/users/23204450/portsmouth-dumpster-rental?tab=profile",
    "https://www.directory2020.com/home-improvement-tools/portsmouth-dumpster-rental",
    "https://www.moneysaversguide.com/business-services/portsmouth-dumpster-rental",
    "http://www.click4homeservices.com/sample-category/portsmouth-dumpster-rental",
    "https://www.ailoq.com/portsmouth-dumpster-rental",
    "https://bizbookusa.com/listing/portsmouth-dumpster-rental/",
    "https://askubuntu.com/users/1758325/portsmouth-dumpster-rental?tab=profile",
    "https://www.getlisteduae.com/listings/portsmouth-dumpster-rental",
    "https://www.localstar.org/portsmouth-dumpster-rental",
    "https://mathoverflow.net/users/520200/portsmouth-dumpster-rental?tab=profile",
    "https://www.webwiki.com/portsmouthdumpsterrental.com",
    "https://cally.com/event/index/fzwj3aq6pucnfqxn",
    "https://www.bunnydirectories.com/local-deals/portsmouth-dumpster-rental",
    "https://flipboard.com/@portsmouthd22aa/portsmouth-dumpster-rental-shgjv4rsy",
    "https://www.cakeresume.com/me/pdumpsterrental",
    "https://www.digitalbusinessdirectory.online/portsmouth-dumpster-rental",
    "https://www.ayurvedaliving.org/ayurveda-association/portsmouth-dumpster-rental",
    "https://www.bestincom.com/contractors/portsmouth-dumpster-rental",
    "https://stackapps.com/users/121259/portsmouth-dumpster-rental?tab=profile",
    "http://www.countrypwr.com/portsmouth-dumpster-rental",
    "https://www.bpublic.com/professional-services/portsmouth-dumpster-rental",
    "https://local.gocommercially.com/professional-services/portsmouth-dumpster-rental",
    "http://www.247globalbusinesssolutions.com/professional-services/portsmouth-dumpster-rental",
    "https://www.shopsmall.directory/pro/20240106030556",
    "https://www.pinterest.com/portsmouthdumpsterrental/",
    "https://buynow-us.com/details.php?id=636877&key=251dd4bc6a074d9352f82347706950a0",
    "https://medium.com/@pdumpsterrental",
    "https://www.tripadvisor.com/Profile/pdumpsterrental",
    "https://thelocal.directory/business-directory/portsmouth-dumpster-rental/",
    "https://www.quora.com/profile/Portsmouth-Dumpster-Rental",
    "https://www.insertbiz.com/listing/portsmouth-dumpster-rental/",
    "https://www.localhomeservicepros.com/architects-builders-engineers/portsmouth-dumpster-rental",
    "https://meta.stackexchange.com/users/1454141/portsmouth-dumpster-rental?tab=profile",
    "https://www.addonbiz.com/listing/portsmouth-dumpster-rental/",
    "https://penzu.com/p/23017d6b30e20073",
    "https://www.skillshare.com/en/profile/Skillshare-Member/639695739",
    "https://www.fixerhub.com/professional-service/portsmouth-dumpster-rental",
    "https://list.ly/list/9NS7-portsmouth-dumpster-rental?make_list_mode=true",
    "https://www.topgoogle.com/listing/portsmouth-dumpster-rental/",
    "https://www.homemavenmember.com/local-businesses/portsmouth-dumpster-rental",
    "https://speakerdeck.com/pdumpsterrental",
    "https://pdumpsterrental.livejournal.com/483.html",
    "https://soundcloud.com/pdumpsterrental-494237234",
    "https://www.localbusinesslisting.org/portsmouth-dumpster-rental",
    "https://webforcompany.com/portsmouth-dumpster-rental/",
    "https://www.gravitysplash.com/b/portsmouth-dumpster-rental/",
    "https://www.meetyourmarkets.com/portsmouth-dumpster-rental",
    "https://www.techdirectory.io/professional-services/portsmouth-dumpster-rental",
    "https://www.ourbizdirectory.com/consultants/portsmouth-dumpster-rental",
    "http://www.spiritualpool.com/portsmouth-dumpster-rental",
    "https://www.bahtlist.com/construction/portsmouth-dumpster-rental",
    "https://www.irooni.co/professional-services/portsmouth-dumpster-rental",
    "http://www.southeastvalley.com/home-helpers/portsmouth-dumpster-rental",
    "https://www.onmap.ae/business-services/portsmouth-dumpster-rental",
    "http://www.aroundhendrickscounty.com/home-services/portsmouth-dumpster-rental",
    "https://www.buffalosbest.com/business-services/portsmouth-dumpster-rental",
    "https://www.marketplace2020s.com/professional-services/portsmouth-dumpster-rental",
    "https://www.counselingnearme.com/in-person-counseling/portsmouth-dumpster-rental",
    "https://www.encaf.org/group-members/portsmouth-dumpster-rental",
    "http://www.hrtechmarket.com/all-hr-tech-solutions/portsmouth-dumpster-rental",
    "https://www.virtualmallspace.com/professional-services/portsmouth-dumpster-rental"
];
const DirectoriesOnTheWeb = () => {
    return (
        <>
            <SEO/>
            <PageHeader header={"Find us on the web!"}/>
            <div className="main_wrapper">
                <Box py={20} mt={0}>
                    <List spacing={3} textAlign={'center'}>
                        {urls.map((url, index) => (
                            <ListItem key={index}>
                                <Link href={url} isExternal color="blue.500" target={"_blank"} rel={"noreferrer"}>
                                    {url}
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </div>
        </>
    );
};

export default DirectoriesOnTheWeb;