import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const WasteManagement = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Environmentally-Friendly Waste Management in Portsmouth, VA
        </Heading>
        <Text>
          Safeguarding the planet's health is a shared responsibility, and
          effective waste disposal plays a pivotal role in this collective
          mission. At Portsmouth Dumpster Rental, we have embraced this mandate,
          dedicating ourselves to offering solutions that not only meet our
          clients' needs but also actively contribute to environmental
          protection. Choose us for a commitment to responsible waste
          management, where our services align with both client satisfaction and
          the preservation of our environment.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <strong>Eco-Conscious Processes:</strong> Our methods emphasize
            recycling and minimize landfill contributions.
          </ListItem>
          <ListItem>
            <strong>Stay Green:</strong> Choose a service that marries
            efficiency with eco-responsibility.
          </ListItem>
          <ListItem>
            <strong>Local Commitment:</strong> Our goal is to ensure Portsmouth
            remains beautiful and sustainable for generations.
          </ListItem>
          <ListItem>
            <strong>A Brighter Future:</strong> Partner with us to support an
            eco-friendlier Portsmouth community.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Box>
  );
};
