import React from "react";
import { Heading, Stack, Text } from "@chakra-ui/react";

export const BlogPost2 = () => {
  return (
    <Stack spacing={3}>
      <Text>
        Undertaking a home improvement project in Portsmouth, Virginia? Learn
        how a residential dumpster rental can streamline your waste management
        process. Whether you're renovating, decluttering, or landscaping, our
        dumpsters are a convenient solution.
      </Text>
      <Heading as={"h3"} size={"md"} fontWeight={"bold"}>
        Perfect for Various Home Projects
      </Heading>
      <Text>
        Discover the versatility of our dumpster range, perfectly suited for a
        variety of residential projects. Tailor your choice to the specific
        needs of your project, whether it's a smaller undertaking like a garage
        cleanout or a larger renovation. Our diverse sizes ensure you can select
        the perfect dumpster to efficiently manage waste for projects of any
        scale. Trust Portsmouth Dumpster Rental to provide the ideal solution
        for your residential needs, making the waste disposal process seamless
        and convenient.
      </Text>
      <Heading as={"h3"} size={"md"} fontWeight={"bold"}>
        Stress-Free Rental Experience
      </Heading>
      <Text>
        Experience a hassle-free rental journey with us. From straightforward
        booking to punctual delivery and pickup, we take care of the logistics,
        allowing you to concentrate on your project without unnecessary
        concerns. Trust Portsmouth Dumpster Rental to streamline the rental
        process, ensuring a seamless and stress-free experience for our valued
        customers.
      </Text>
      <Heading as={"h3"} size={"md"} fontWeight={"bold"}>
        Safe and Efficient Disposal
      </Heading>
      <Text>
        Our dumpsters facilitate the safe and efficient disposal of various
        types of household waste, creating a clean and secure work environment.
        Choose Portsmouth Dumpster Rental for a reliable solution that
        prioritizes cleanliness and safety, enabling you to focus on your
        project without worrying about waste management challenges.
      </Text>
      <Heading as={"h3"} size={"md"} fontWeight={"bold"}>
        Eco-Conscious Disposal Methods
      </Heading>
      <Text>
        Demonstrating our commitment to environmentally responsible practices,
        we ensure that your project's waste is managed in an eco-friendly
        manner. Trust us to handle waste disposal with sustainability in mind,
        contributing to a greener and healthier environment. Choose Portsmouth
        Dumpster Rental for services aligned with your commitment to
        environmental responsibility.
      </Text>
      <Text>
        Opt for Portsmouth Dumpster Rental to enjoy a residential dumpster
        rental experience that is not only convenient and reliable but also
        eco-friendly. We prioritize your satisfaction and environmental
        responsibility, making us the ideal choice for your waste management
        needs.
      </Text>
    </Stack>
  );
};
