import React from "react";
import {posts} from "./posts";
import {Box, Button, Heading, Hide, Link, List, ListItem, Stack, Text, UnorderedList,} from "@chakra-ui/react";
import {ResidentialDumpster} from "../Components/ResidentialDumpster/ResidentialDumpster";
import {CommercialDumpster} from "../Components/CommercialDumpster/CommercialDumpster";
import {RollOffDumpster} from "../Components/RollOffDumpster/RollOffDumpster";
import {WasteManagement} from "../Components/WasteManagement/WasteManagement";
import portsmouthdumpsterrentalsmeta from "../assets/images/portsmouthdumpsterrentalsmeta.jpg";
import {AiFillStar, AiOutlineSmile} from "react-icons/ai";
import {FaHandHoldingUsd} from "react-icons/fa";
import {MdLocalShipping} from "react-icons/md";
import {SameDayDumpster} from "../Components/SameDayDumpster/SameDayDumpster";
import {EventWasteManagement} from "../Components/EventWasteManagement/EventWasteManagement";
import {JunkRemovalServices} from "../Components/JunkRemovalServices/JunkRemovalServices";
import {LongTermDumpsterRentals} from "../Components/LongTermDumpsterRentals/LongTermDumpsterRentals";

export const SiteData = {
    hoursOfOpperation: "8am - 5pm Monday - Saturday",
    city: "Portsmouth,VA",
    phoneNumber: "(757) 704-5492",
    telLink: "tel:7577045492",
    title: "Dumpster Rentals in Portsmouth, VA | 757-704-5492",
    buttonCtaText: "Call for a Dumpster Quote in Portsmouth",
    keywords:
        "Dumpster, rental, efficient, affordable, reliable, Portsmouth, VA, waste management, junk removal",
    footerTextCr: "Portsmouth Dumpster Rentals, All rights reserved.",
    ogImage: portsmouthdumpsterrentalsmeta,
    navLinks: [
        {
            title: "Home",
            href: "/",
        },
        {
            title: "Services",
            href: "/services",
        },
        {
            title: "Blog",
            href: "/blog",
        },
        {
            title: "About",
            href: "/about",
        },
        {
            title: "Contact Us",
            href: "/contact",
        },
    ],
    signupForm: {
        intro: () => (
            <>
                Portsmouth Dumpster Rentals provides efficient and reliable dumpster
                rental services to Portsmouth, Virginia, and the surrounding regions.
                Whether you have construction debris, need{" "}
                <a
                    href="https://www.lafayettejunkremovalpros.com"
                    target={"_blank"}
                    rel={"noreferrer"}
                >
                    junk removal
                </a>
                , or need a dumpster for a significant event, our team ensures timely
                delivery and pickup at your convenience.
            </>
        ),
        servicesTitle: "Our Services:",
    },
    homepage: {
        servicesTitle: "Why Portsmouth Chooses Us for Dumpster Rentals",
        servicesHeader:
            "Comprehensive Dumpster Rental Solutions for Every Requirement",
        url: "https://www.portsmouthdumpsterrentals.com",
        metaData: {
            title:
                "Portsmouth Dumpster Rentals: Virginia's Trusted Partner for Waste Management | Call 757-704-5492",
            description:
                "Your top choice for waste management in Portsmouth, VA. Contact us for reliable and prompt dumpster rental services in Portsmouth and the surrounding areas.",
        },
        h1: "Premier Dumpster Rental Services in Portsmouth, VA",
        subtitle:
            "Your Dependable Waste Management Partner in Portsmouth, Virginia",
        h2: "Choose the Right Dumpster Size for Your Task",
        h3: "From small to large projects, we offer a range of dumpster sizes tailored to your requirements.",
        sizes: [
            {
                title: "4 Yard",
                nickname: "Mini Hauler",
                description:
                    "A compact choice for small home clear-outs or minor landscaping projects. Conveniently sized for easy placement.",
            },
            {
                title: "7 Yard",
                nickname: "Handyman",
                description:
                    "A favorite for DIY enthusiasts. Suitable for room clear-outs or small remodeling projects and easy to fit in your driveway.",
            },
            {
                title: "12 Yard",
                nickname: "Project Partner",
                description:
                    "The go-to for household junk removal or single room renovations. A balance of size and utility works for most small renovations.",
            },
            {
                title: "22 Yard",
                nickname: "Constructor",
                description:
                    "Ideal for construction projects or residential tasks like basement and attic cleanouts. Works for medium size renovations.",
            },
            {
                title: "32 Yard",
                nickname: "Renovator",
                description:
                    "Perfect for full-house renovations and major commercial projects. Spacious enough for your bulky items and material.",
            },
            {
                title: "45 Yard",
                nickname: "Colossus",
                description:
                    "Our roomiest option. Tailored for the largest of projects and suitable to handle all of your substantial and heavy debris.",
            },
        ],
        values: [
            {
                id: 7,
                number: "5-Star",
                name: "Rated Service",
                icon: AiFillStar,
            },
            {
                id: 3,
                number: "Same Day",
                name: "Delivery Available",
                icon: MdLocalShipping,
            },
            {
                id: 4,
                number: "2000+",
                name: "Happy Clients",
                icon: AiOutlineSmile,
            },
            {
                id: 5,
                number: "Competitive",
                name: "Pricing Guarantee",
                icon: FaHandHoldingUsd,
            },
        ],
        content: [
            <>
                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Portsmouth Dumpster Rental: Premier Dumpster Solutions in
                        Portsmouth, VA
                    </Heading>
                    Welcome to Portsmouth Dumpster Rental, your premier source for
                    top-notch <a href={"https://www.mcallendumpsterservices.com/"} target={"_blank"} rel={"noreferrer"}>dumpster
                    rental</a> services in Portsmouth, Virginia. Our
                    comprehensive offerings cater to a diverse range of needs,
                    encompassing residential and commercial dumpster rentals, roll-off
                    dumpster services, and eco-friendly waste management solutions. As a
                    leading choice in the region, our commitment to efficiency and
                    environmental responsibility sets us apart. Whether you're undertaking
                    a home project or managing commercial waste, trust Portsmouth Dumpster
                    Rental for reliable and eco-conscious services tailored to meet your
                    diverse requirements.
                </Text>

                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Tailored Dumpster Rental Services
                    </Heading>
                    We offer a variety of specialized dumpster rental services to meet
                    your unique needs:
                    <UnorderedList>
                        <ListItem>
                            <Link href={"/residential-dumpster-rentals"}>
                                Residential Dumpster Rentals
                            </Link>
                            : Tailored for homeowners in Portsmouth, our residential dumpsters
                            streamline cleanouts, renovations, and landscaping projects,
                            ensuring a clutter-free and efficient waste management experience.
                        </ListItem>
                        <ListItem>
                            <Link href={"/commercial-dumpster-rentals"}>
                                Commercial Dumpster Rentals
                            </Link>
                            : Optimal waste solutions for businesses in Portsmouth. From
                            office cleanouts to ongoing construction debris, our commercial
                            dumpsters are designed for efficiency and reliability.
                        </ListItem>
                        <ListItem>
                            <Link href={"/roll-off-dumpster-rentals"}>
                                Roll-Off Dumpster Rentals
                            </Link>
                            : Roll-off dumpsters offering unparalleled versatility for
                            Portsmouth’s diverse disposal needs. Perfect for large-scale
                            construction, renovations, and extensive cleanouts.
                        </ListItem>
                        <ListItem>
                            <Link href={"/waste-management"}>
                                Eco-Friendly Waste Management
                            </Link>
                            : Committed to Portsmouth’s environment, our eco-friendly approach
                            emphasizes recycling, responsible disposal, and sustainable
                            practices in all waste management endeavors.
                        </ListItem>
                        <ListItem>
                            <Link href={"/same-day-dumpster-rentals"}>
                                Same-Day Dumpster Rentals
                            </Link>
                            : Immediate dumpster solutions in Portsmouth for urgent disposal
                            needs. Our same-day service ensures quick delivery, making it
                            perfect for last-minute projects and tight deadlines.
                        </ListItem>
                        <ListItem>
                            <Link href={"/event-waste-management"}>
                                Event Waste Management
                            </Link>
                            : Customized waste management services for events in Portsmouth.
                            Whether it's a community festival, corporate event, or private
                            gathering, our services keep your event clean and organized.
                        </ListItem>
                        <ListItem>
                            <Link href={"/junk-removal-services"}>Junk Removal Services</Link>
                            : Efficient and eco-conscious junk removal services in Portsmouth.
                            We handle everything from furniture and appliances to general
                            clutter, making your space clean and livable.
                        </ListItem>
                        <ListItem>
                            <Link href={"/long-term-dumpster-rentals"}>
                                Long-Term Dumpster Rentals
                            </Link>
                            : Ideal for extended projects in Portsmouth requiring continuous
                            waste management. Our long-term rental options offer flexibility,
                            reliability, and consistent support.
                        </ListItem>
                    </UnorderedList>
                    Our services are designed to provide efficient, hassle-free waste
                    management, whether for a small home project or a large commercial
                    undertaking.
                </Text>

                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Why Partner with Portsmouth Dumpster Rental?
                    </Heading>
                    Choosing Portsmouth Dumpster Rental means opting for a service that
                    values:
                    <UnorderedList>
                        <ListItem>
                            Customized solutions to meet diverse waste disposal needs.
                        </ListItem>
                        <ListItem>
                            Efficient, reliable service with a focus on customer satisfaction.
                        </ListItem>
                        <ListItem>
                            Commitment to environmentally responsible waste management
                            practices.
                        </ListItem>
                        <ListItem>
                            Competitive pricing with transparent, no-hidden-fee policies.
                        </ListItem>
                        <ListItem>
                            A team of professionals dedicated to providing exceptional
                            service.
                        </ListItem>
                    </UnorderedList>
                    Our approach is rooted in understanding and addressing the unique
                    challenges faced by our clients in Portsmouth, ensuring a seamless and
                    effective dumpster rental experience.
                </Text>

                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Sustainability and Eco-Friendly Practices
                    </Heading>
                    At Portsmouth Dumpster Rental, our passion lies in protecting the
                    environment through sustainable waste management practices. We are
                    committed to prioritizing recycling and responsible disposal methods
                    to minimize our ecological footprint. With a focus on reducing waste
                    and fostering environmental consciousness, our goal is to make a
                    positive impact within the Portsmouth community. Join us in our
                    commitment to a greener future, where responsible waste management
                    plays a crucial role in preserving the environment for generations to
                    come.
                </Text>

                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Reliable and Flexible Service
                    </Heading>
                    Whether overseeing a large-scale construction project or a small
                    residential cleanup, our team at Portsmouth Dumpster Rental guarantees
                    the prompt and efficient fulfillment of your dumpster needs.
                    Recognizing the significance of timely service, we provide flexible
                    scheduling options to seamlessly accommodate your project timeline.
                    Rest assured that regardless of the scale of your undertaking, our
                    commitment to meeting your dumpster requirements ensures a hassle-free
                    experience tailored to your specific needs.
                </Text>

                <Text>
                    <Heading as="h2" size={"md"} pb="2">
                        Contact Portsmouth Dumpster Rental
                    </Heading>
                    For expert dumpster rental services in Portsmouth, VA,{" "}
                    <Link href={"tel:7577045492"}>call us at (757) 704-5492</Link>. Our
                    team is ready to assist you in selecting the right dumpster for your
                    project and provide you with a free, no-obligation quote. Experience
                    the Portsmouth Dumpster Rental difference – where your project's
                    success and environmental responsibility go hand in hand.
                </Text>

                <Button
                    as={"a"}
                    href={"tel:7577045492"}
                    bg={"#17b644"}
                    fontSize={{base: "20px", md: "23px"}}
                    color={"white"}
                    fontWeight={"bold"}
                    _hover={{bg: "#06c03b", color: "white", cursor: "pointer"}}
                    p={"16px 30px"}
                    borderRadius={0}
                    mt={"2rem"}
                    lineHeight={"26px"}
                    minH={"66px"}
                    width={"100%"}
                    maxW={"400px"}
                >
                    <i className="fa fa-phone" aria-hidden="true"/>
                    &nbsp;&nbsp;Call for <Hide below="md">Immediate</Hide> Assistance
                </Button>
            </>,
        ],
        services: [
            {
                id: "0",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/residential-dumpster-rentals"}>
                                Residential Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Elevate your Portsmouth home projects with our diverse dumpster
                        options, ideal for efficient waste handling. Enjoy hassle-free
                        services tailored to homeowners' needs for stress-free improvements.
                        Choose us for a streamlined experience, making our dumpsters the
                        cornerstone of your Portsmouth projects.
                    </Text>
                ),
            },
            {
                id: "1",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/commercial-dumpster-rentals"}>
                                Commercial Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Enhance business waste disposal in Portsmouth with our top-quality
                        dumpsters, designed for commercial use. Trust our commitment to
                        excellence for reliable and efficient waste management, elevating
                        your business operations in Portsmouth.
                    </Text>
                ),
            },
            {
                id: "2",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/roll-off-dumpster-rentals"}>
                                Roll-Off Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Explore versatile dumpsters in Portsmouth for easy loading and
                        convenient placement. Ideal for various projects, our dumpsters
                        offer flexibility and efficiency in waste disposal. Whether it's
                        renovations, construction, or other tasks, choose our hassle-free
                        options for streamlined waste management.
                    </Text>
                ),
            },
            {
                id: "3",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/waste-management"}>
                                Eco-Friendly Waste Management
                            </Link>
                        </b>{" "}
                        Choose our Portsmouth services for eco-friendly waste management,
                        prioritizing recycling and sustainability. Contribute to a greener
                        community with efficient recycling and sustainable disposal methods.
                        Opt for our services to make a positive impact, managing waste
                        effectively while preserving Portsmouth's environment.
                    </Text>
                ),
            },
            {
                id: "4",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/same-day-dumpster-rentals"}>
                                Same-Day Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Experience prompt same-day dumpster delivery for urgent waste
                        disposal needs in Portsmouth. Trust us for quick and reliable
                        service to meet your deadlines with efficiency and convenience.
                    </Text>
                ),
            },
            {
                id: "5",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/event-waste-management"}>
                                Event Waste Management
                            </Link>
                        </b>{" "}
                        Elevate Portsmouth events with custom waste solutions for clean and
                        organized venues. Our specialized services offer efficient
                        solutions, prioritizing cleanliness and organization. Choose us for
                        tailored waste solutions to create positive and sustainable event
                        environments.
                    </Text>
                ),
            },
            {
                id: "6",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/junk-removal-services"}>Junk Removal Services</Link>
                        </b>{" "}
                        Choose our quick and eco-friendly junk removal in Portsmouth. We
                        handle various items, prioritizing sustainability. From furniture to
                        clutter, our services streamline the process for a clean and
                        responsible space.
                    </Text>
                ),
            },
            {
                id: "7",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/long-term-dumpster-rentals"}>
                                Long-Term Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Perfect for extended projects in Portsmouth, our flexible rental
                        periods and reliable service ensure stress-free waste management.
                        Choose us for convenient and dependable support throughout your
                        project.
                    </Text>
                ),
            },
        ],
        featuredServices: [
            {
                id: "0",
                name: "Residential Dumpster Rentals",
                path: "/residential-dumpster-rentals",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/residential-dumpster-rentals"}>
                                Residential Dumpster Rentals
                            </Link>
                        </b>{" "}
                        When home projects beckon, Portsmouth Dumpster Rental answers! From
                        decluttering sessions to full-scale renovations, our range of
                        dumpsters fit every need. Serving Portsmouth households with timely
                        drop-offs and efficient pickups, we ensure a hassle-free waste
                        disposal experience. Discover the convenience Portsmouth residents
                        rave about.
                    </Text>
                ),
            },
            {
                id: "1",
                name: "Commercial Dumpster Rentals",
                path: "/commercial-dumpster-rentals",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/commercial-dumpster-rentals"}>
                                Commercial Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Empowering businesses in Portsmouth, VA with top-notch waste
                        disposal solutions. Whether you're renovating an office or have
                        regular waste needs, our durable dumpsters are your trusted
                        partners. Flexible rental periods, swift services, and a{" "}
                        <Link href={"/blog/commercial-waste-management"}>commitment</Link>{" "}
                        to Portsmouth's business community set us apart. Dive into tailored
                        solutions with Portsmouth Dumpster Rental.
                    </Text>
                ),
            },
            {
                id: "2",
                name: "Roll-Off Dumpster Rentals",
                path: "/roll-off-dumpster-rentals",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/roll-off-dumpster-rentals"}>
                                Roll-Off Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Navigating waste disposal needs with adaptability and precision. Our
                        roll-off dumpsters, ideal for a spectrum of tasks, offer easy
                        loading and flexible placement options. Be it a home cleanout or a
                        commercial gig, witness unparalleled ease with our service.{" "}
                        <Link href={"/"}>Portsmouth Dumpster Rental</Link> guarantees
                        efficiency and reliability in every roll-off.
                    </Text>
                ),
            },
            {
                id: "3",
                name: "Waste Management",
                path: "/waste-management",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/waste-management"}>
                                Eco-Friendly Waste Management
                            </Link>
                        </b>{" "}
                        We're not just about disposing; we're about recycling and
                        sustainability. Partner with a service that cherishes Portsmouth's
                        environment as much as you do. Our green processes prioritize
                        recycling, reducing landfill load, and keeping our community clean.
                        Be part of the green revolution with{" "}
                        <Link href={"/"}>Portsmouth Dumpster Rental</Link>.
                    </Text>
                ),
            },
            {
                id: "4",
                name: "Same-Day Dumpster Rentals",
                path: "/same-day-dumpster-rentals",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/same-day-dumpster-rentals"}>
                                Same-Day Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Need a dumpster quickly in Portsmouth? Our same-day rental service
                        provides rapid delivery to meet urgent waste disposal needs,
                        ensuring efficiency without compromising on service quality.
                    </Text>
                ),
            },
            {
                id: "5",
                name: "Event Waste Management",
                path: "/event-waste-management",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/event-waste-management"}>
                                Event Waste Management
                            </Link>
                        </b>{" "}
                        Specialized waste solutions for events in Portsmouth. Whether it's a
                        festival, corporate event, or wedding, our dumpsters ensure clean
                        and well-managed venues, tailored to your event's specific needs.
                    </Text>
                ),
            },
            {
                id: "6",
                name: "Junk Removal Services",
                path: "/junk-removal-services",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/junk-removal-services"}>Junk Removal Services</Link>
                        </b>{" "}
                        Comprehensive junk removal for Portsmouth residents. From old
                        furniture to general clutter, our service is quick, convenient, and
                        environmentally friendly, clearing the way for a cleaner space.
                    </Text>
                ),
            },
            {
                id: "7",
                name: "Long-Term Dumpster Rentals",
                path: "/long-term-dumpster-rentals",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/long-term-dumpster-rentals"}>
                                Long-Term Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Tailored long-term dumpster rental solutions for ongoing projects in
                        Portsmouth. Our flexible rental periods and reliable service ensure
                        continuous support for your extended waste management needs.
                    </Text>
                ),
            },
        ],
        footerHeading: "Get in Touch with Portsmouth Dumpster Rental",
        footerText: (
            <>
                For all your{" "}
                <a
                    href="https://www.portsmouthdumpsterrental.com/"
                    target={"_blank"}
                    rel={"noreferrer"}
                >
                    dumpster rental needs
                </a>{" "}
                in Portsmouth and surrounding areas, we are at your service. Reach out
                to us for any project, big or small, at any time. Whether you need a
                quick dumpster delivery or have queries about our services,{" "}
                <Link href={"tel:7577045492"}>call us at (757) 704-5492</Link> and we'll
                be happy to assist. With Portsmouth Dumpster Rental, your project's
                success is our priority.
            </>
        ),
        footerText2: "Dedicated to Excellence in Service and Customer Care",
        closingText:
            "Portsmouth Dumpster Rentals is committed to efficient and eco-friendly waste management solutions. Select from our range of sizes and get the best fit for your needs. Call us today at (757) 704-5492 to discuss your dumpster requirements.",
    },
    services: {
        metaData: {
            title:
                "Top-Quality Dumpster Rental Services in Portsmouth | Portsmouth Dumpster Rentals",
            description:
                "Explore our range of dumpster rental services in Portsmouth. From small cleanups to significant projects, we ensure timely and professional waste management solutions.",
        },
        url: "https://www.portsmouthdumpsterrentals.com/services",
        h1: "Portsmouth, Virginia Dumpster Rental Services: Reliable & Eco-Friendly Solutions",
        content: (
            <Box>
                <Stack spacing={5}>
                    <Heading as="h2" size="xl">
                        Portsmouth Dumpster Rental: Your Premier Waste Management Solution
                        in Portsmouth, VA
                    </Heading>
                    <Text>
                        Portsmouth Dumpster Rental is dedicated to providing top-notch
                        dumpster rental and waste management services in Portsmouth, VA.
                        Specializing in a range of solutions from residential cleanups to
                        commercial construction projects, we deliver reliable, efficient,
                        and environmentally responsible waste disposal services. Our
                        commitment to customer satisfaction and environmental stewardship
                        sets us apart as the leading service provider in the area.
                    </Text>

                    <Heading as="h3" size="lg">
                        Why Choose Portsmouth Dumpster Rental
                    </Heading>
                    <List spacing={3}>
                        <ListItem>
                            <Text>
                                <b>Versatile Dumpster Options:</b> Catering to both residential
                                and commercial needs, our diverse selection of dumpster sizes
                                ensures the right fit for every project.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Eco-friendly Practices:</b> We prioritize sustainable waste
                                disposal, focusing on recycling and reducing the environmental
                                impact of your waste.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Local Expertise:</b> Our deep understanding of Portsmouth’s
                                regulations and community needs means efficient and compliant
                                service every time.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Flexible Rental Periods:</b> We offer adjustable rental
                                durations to suit your project timeline, providing convenience
                                and cost-effectiveness.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Customer-Centric Service:</b> Our team is committed to
                                exceptional service, ensuring your experience is smooth,
                                hassle-free, and tailored to your needs.
                            </Text>
                        </ListItem>
                    </List>

                    <Heading as="h3" size="lg">
                        Our Comprehensive Dumpster Rental Services
                    </Heading>
                    <List spacing={3}>
                        <ListItem>
                            <Text>
                                <Link href={"/residential-dumpster-rentals"}>
                                    <b>Residential Dumpster Rentals:</b>
                                </Link>{" "}
                                Perfect for home renovations, cleanouts, and landscaping
                                projects.{" "}
                                <Link href={"/residential-dumpster-rentals"}>
                                    Learn more about our residential services.
                                </Link>
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <Link href={"/commercial-dumpster-rentals"}>
                                    <b>Commercial Dumpster Rentals:</b>
                                </Link>{" "}
                                Ideal for businesses, construction sites, and large-scale waste
                                management.{" "}
                                <Link href={"/commercial-dumpster-rentals"}>
                                    Discover our commercial solutions.
                                </Link>
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <Link href={"/roll-off-dumpster-rentals"}>
                                    <b>Roll-Off Dumpster Rentals:</b>
                                </Link>{" "}
                                Flexible and convenient for projects requiring easy loading and
                                significant waste capacity.{" "}
                                <Link href={"/roll-off-dumpster-rentals"}>
                                    Explore our roll-off options.
                                </Link>
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <Link href={"/same-day-dumpster-rentals"}>
                                    <b>Same-Day Dumpster Rentals:</b>
                                </Link>{" "}
                                Quick solutions for urgent waste disposal needs.{" "}
                                <Link href={"/same-day-dumpster-rentals"}>
                                    Learn about our same-day service.
                                </Link>
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <Link href={"/long-term-dumpster-rentals"}>
                                    <b>Long-Term Dumpster Rentals:</b>
                                </Link>{" "}
                                Ideal for ongoing projects requiring continuous waste
                                management.{" "}
                                <Link href={"/long-term-dumpster-rentals"}>
                                    Find out about our long-term rentals.
                                </Link>
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <Link href={"/event-waste-management"}>
                                    <b>Event Waste Management:</b>
                                </Link>{" "}
                                Efficient waste solutions for events of all sizes, ensuring
                                cleanliness and compliance.{" "}
                                <Link href={"/event-waste-management"}>
                                    More on event rentals.
                                </Link>
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <Link href={"/junk-removal-services"}>
                                    <b>Junk Removal Services:</b>
                                </Link>{" "}
                                Comprehensive solutions for removing unwanted items and clutter.{" "}
                                <Link href={"/junk-removal-services"}>
                                    See our junk removal services.
                                </Link>
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <Link href={"/waste-management"}>
                                    <b>Eco-Friendly Waste Management:</b>
                                </Link>{" "}
                                Committed to sustainable practices in every aspect of our
                                service.{" "}
                                <Link href={"/waste-management"}>
                                    Discover how we prioritize the environment.
                                </Link>
                            </Text>
                        </ListItem>
                    </List>

                    <Heading as="h3" size="lg">
                        Customized Solutions for Every Need
                    </Heading>
                    <Text>
                        We understand that every project is unique. That’s why Portsmouth
                        Dumpster Rental offers customized dumpster rental solutions to meet
                        the specific needs of your project, whether personal or commercial.
                        Our team is equipped to provide professional guidance and tailored
                        services, ensuring your waste management is efficient, effective,
                        and environmentally responsible.
                    </Text>

                    <Heading as="h3" size="lg">
                        Prioritizing Sustainability and Customer Satisfaction
                    </Heading>
                    <Text>
                        At Portsmouth Dumpster Rental, we are committed to not just meeting
                        but exceeding your expectations. Our focus on eco-friendly
                        practices, coupled with our dedication to customer satisfaction,
                        ensures a service that you can rely on and trust, every time.
                    </Text>

                    <Heading as="h3" size="lg">
                        Contact Portsmouth Dumpster Rental Today
                    </Heading>
                    <Text>
                        For comprehensive dumpster rental and waste management services in
                        Portsmouth, VA, look no further than Portsmouth Dumpster Rental.{" "}
                        <Link href={"/contact"}>Contact us</Link> today to discuss your
                        needs and find out how we can assist you in managing your waste
                        efficiently and responsibly.
                    </Text>
                </Stack>
            </Box>
        ),
        services: [
            {
                id: "0",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/residential-dumpster-rentals"}>
                                Residential Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Streamline your home projects in Portsmouth with our tailored
                        dumpster rental services. Quick drop-offs, efficient pickups, and
                        unmatched customer support ensure a hassle-free waste disposal
                        experience.
                    </Text>
                ),
            },
            {
                id: "1",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/commercial-dumpster-rentals"}>
                                Commercial Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Optimize waste management for your Portsmouth business. Ideal for
                        office renovations, retail cleanouts, and regular disposals, our
                        commercial dumpsters offer reliable and adaptable solutions.
                    </Text>
                ),
            },
            {
                id: "2",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/roll-off-dumpster-rentals"}>
                                Roll-Off Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Versatile roll-off dumpsters perfect for a variety of projects in
                        Portsmouth. From construction sites to large residential cleanouts,
                        our roll-offs provide easy loading and flexible placement.
                    </Text>
                ),
            },
            {
                id: "3",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/waste-management"}>
                                Eco-Friendly Waste Management
                            </Link>
                        </b>{" "}
                        Leading the way in sustainable waste disposal in Portsmouth. Our
                        eco-friendly approach prioritizes recycling and responsible waste
                        handling, ensuring an environmentally conscious service.
                    </Text>
                ),
            },
            {
                id: "4",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/same-day-dumpster-rentals"}>
                                Same-Day Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Need a dumpster quickly in Portsmouth? Our same-day rental service
                        provides rapid delivery to meet urgent waste disposal needs,
                        ensuring efficiency without compromising on service quality.
                    </Text>
                ),
            },
            {
                id: "5",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/event-waste-management"}>
                                Event Waste Management
                            </Link>
                        </b>{" "}
                        Specialized waste solutions for events in Portsmouth. Whether it's a
                        festival, corporate event, or wedding, our dumpsters ensure clean
                        and well-managed venues, tailored to your event's specific needs.
                    </Text>
                ),
            },
            {
                id: "6",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/junk-removal-services"}>Junk Removal Services</Link>
                        </b>{" "}
                        Comprehensive junk removal for Portsmouth residents. From old
                        furniture to general clutter, our service is quick, convenient, and
                        environmentally friendly, clearing the way for a cleaner space.
                    </Text>
                ),
            },
            {
                id: "7",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/long-term-dumpster-rentals"}>
                                Long-Term Dumpster Rentals
                            </Link>
                        </b>{" "}
                        Tailored long-term dumpster rental solutions for ongoing projects in
                        Portsmouth. Our flexible rental periods and reliable service ensure
                        continuous support for your extended waste management needs.
                    </Text>
                ),
            },
        ],
    },
    contact: {
        url: "https://www.portsmouthdumpsterrental.com/contact/",
        metaData: {
            title:
                "Portsmouth Dumpster Rental - Your Waste Management Experts | Call Us Today",
            description:
                "Contact Portsmouth Dumpster Rental for reliable, eco-friendly, and affordable solutions. Available 24/7 for all your waste management needs.",
        },
        h1: "Contact Portsmouth Dumpster Rental Today",
        subtitle: () => (
            <>
                For trustworthy and efficient dumpster rental services in Portsmouth,
                VA, Portsmouth Dumpster Rental is your go-to choice. Need a dumpster for
                a renovation, cleanup, or construction project? Call us at{" "}
                <Link href={"tel:7577045492"}>call us at (757) 704-5492</Link> or{" "}
                <Link href={"/contact"}>send a message</Link>. We're committed to
                providing responsive and eco-friendly waste management solutions.
            </>
        ),
        sectionHeading: "Choose Local Expertise for Your Dumpster Needs",
        signUpDescription:
            "Interested in our dumpster rental services? Fill out our contact form for a quick response. We prioritize customer satisfaction and timely service.",
        feedbackText:
            "Your feedback helps us serve you better. Tell us about your experience or share your suggestions. At Portsmouth Dumpster Rental, we're dedicated to continuous improvement and customer satisfaction.",
        closingText:
            "For dependable, eco-friendly dumpster rentals in Portsmouth, VA, trust our experienced team.",
        valueProps: [
            {
                title: "Prompt & Reliable Service:",
                description:
                    "Experience hassle-free dumpster rental with our timely delivery and pickup services in the Portsmouth area. We’re known for our reliability and efficiency.",
            },
            {
                title: "Diverse Range of Dumpsters:",
                description:
                    "We offer a variety of dumpster sizes to suit any project, ensuring you have the right solution for your specific needs in Portsmouth.",
            },
            {
                title: "Sustainable Waste Solutions:",
                description:
                    "Committed to the environment, our waste disposal practices focus on sustainability, making us a responsible choice in Portsmouth.",
            },
            {
                title: "Competitive, Transparent Pricing:",
                description:
                    "Get the best value with our transparent and affordable pricing for all dumpster rental services in Portsmouth, VA.",
            },
            {
                title: "Customer-Centric Approach:",
                description:
                    "Your satisfaction is our priority. We provide personalized service to meet your specific dumpster rental requirements in Portsmouth.",
            },
        ],
    },
    blog: {
        url: "https://www.portsmouthdumpsterrentals.com/blog",
        metaData: {
            title: "Portsmouth Dumpster Rentals Blog | Expert Advice & Tips",
            description:
                "Stay updated with the latest in waste management with Portsmouth Dumpster Rentals. Insights, tips, and industry know-hows - our team is just a call away.",
        },
        h1: "Waste Management Insights | Portsmouth Dumpster Rentals Blog",
        h2: "Our Latest Posts",
        heading:
            "At Portsmouth Dumpster Rentals, we prioritize our clients by ensuring top-tier service, efficiency, and affordability. Our blog is a rich resource for all waste management needs. Stay tuned for updates and don't hesitate to contact us for any inquiries. Rely on Portsmouth Dumpster Rentals for unparalleled waste management services.",
        posts: posts,
    },
    about: {
        url: "https://www.portsmouthdumpsterrental.com/about/",
        metaData: {
            title:
                "About Portsmouth Dumpster Rental - Premier Dumpster Solutions in Portsmouth, VA",
            description:
                "Learn more about Portsmouth Dumpster Rental, your expert in environmentally responsible and efficient waste management solutions in Portsmouth, VA.",
        },
        h1: "About Portsmouth Dumpster Rental",
        h2: [
            "Our Commitment to Sustainable Waste Management",
            "Experienced Team, Unmatched Service",
            "Diverse Dumpster Rental Services for Every Need",
        ],
        headingText: {
            commitment:
                "Dedicated to providing environmentally conscious and customer-focused dumpster rental services in Portsmouth, Virginia.",
            team: "Our team of waste management experts is equipped with knowledge and experience, ensuring exceptional service for every client.",
            services:
                "Dumpster rental services, tailored for both residential and commercial needs, ensuring effective waste disposal solutions.",
        },
        services: [
            {
                id: "0",
                title: "Residential Dumpster Rentals",
                href: "/residential-dumpster-rentals",
                icon: "MdHome",
                description: (
                    <Text>
                        Ideal for home renovation or cleanout projects in Portsmouth, our
                        residential dumpster services offer a perfect solution. Experience
                        hassle-free waste disposal with our reliable and efficient services
                        tailored to meet the needs of your residential projects. Choose
                        Portsmouth Dumpster Rental for a seamless experience that ensures
                        your home improvement endeavors are supported by reliable waste
                        management solutions.
                    </Text>
                ),
            },
            {
                id: "1",
                title: "Commercial Dumpster Rentals",
                href: "/commercial-dumpster-rentals",
                icon: "FaBuilding",
                description: (
                    <Text>
                        Enhance the efficiency of waste management for your business with
                        our flexible commercial dumpster rental options, designed to cater
                        to various industries in Portsmouth. Our services offer a tailored
                        solution to meet the specific needs of your business, ensuring
                        seamless waste disposal and contributing to the overall operational
                        efficiency of your enterprise. Choose Portsmouth Dumpster Rental for
                        a reliable partner in optimizing waste management for businesses
                        across diverse sectors in Portsmouth.
                    </Text>
                ),
            },
            {
                id: "2",
                title: "Roll-Off Dumpster Rentals",
                href: "/roll-off-dumpster-rentals",
                icon: "MdOutlineRoller",
                description: (
                    <Text>
                        Portsmouth Dumpster Rental offers roll-off dumpsters as the ultimate
                        solution for large-scale waste disposal needs, catering specifically
                        to construction sites and extensive cleanups. Our roll-off dumpsters
                        provide a convenient and efficient way to manage substantial volumes
                        of waste, ensuring a streamlined process for projects of significant
                        scale. Choose us for reliable and spacious roll-off dumpsters that
                        meet the demands of your construction site or extensive cleanup,
                        offering a practical and effective waste management solution.
                    </Text>
                ),
            },
            {
                id: "3",
                title: "Eco-Friendly Waste Management",
                href: "/waste-management",
                icon: "GiRecycle",
                description: (
                    <Text>
                        Demonstrating our commitment to sustainable practices, we provide
                        eco-friendly waste management services in Portsmouth. With a
                        dedicated focus on recycling and responsible disposal, our services
                        align with environmental preservation. Choose us for waste
                        management solutions that not only meet your needs but also
                        contribute to a greener and healthier community in Portsmouth.
                    </Text>
                ),
            },
        ],
    },
    testimonials: {
        h1: "What Our Clients Say",
        heading:
            "Explore what our satisfied customers in Portsmouth have to say about our dumpster rental services. Our commitment to quality service and customer satisfaction speaks for itself.",
        testimonials: [
            {
                avatarUrl: "https://i.pravatar.cc/150?u=a042581f4e34027805e",
                name: "Emily R.",
                logo: undefined,
                title: "Portsmouth, VA",
                quote:
                    '"Portsmouth Dumpster Rental made my home renovation project so much easier! They delivered the dumpster right on time and placed it exactly where we needed. Their team was friendly and very professional. I highly recommend their service for any cleanup needs!"',
            },
            {
                avatarUrl: "https://i.pravatar.cc/150?u=a0425f4e",
                name: "John D.",
                logo: undefined,
                title: "Portsmouth, VA",
                quote:
                    '"I have used Portsmouth Dumpster Rental for several construction projects. Their dumpsters are always clean and in great condition. The delivery and pickup are always prompt and hassle-free. They are my go-to company for all my dumpster needs in Portsmouth."',
            },
            {
                avatarUrl: "https://i.pravatar.cc/150?u=b0537f4d",
                name: "Rachel M.",
                logo: undefined,
                title: "Portsmouth, VA",
                quote:
                    '"I’m impressed with the efficiency of Portsmouth Dumpster Rental. They offered me an affordable rate and excellent customer service. The whole process from ordering to pickup was smooth and easy. Definitely the best choice for dumpster rentals in Portsmouth."',
            },
        ],
    },
    residentialDumpsterRentals: {
        url: "https://www.portsmouthdumpsterrentals.com/residential-dumpster-rentals/",
        metaData: {
            title: "Residential Dumpster Rental Services | Portsmouth VA",
            description:
                "Simplify your home projects with Portsmouth Dumpster Rental. Affordable, hassle-free solutions for homeowners in Portsmouth, VA. Discover our range!",
        },
        h1: "Portsmouth's Trusted Residential Dumpster Rental Service",
        subtitle:
            "Tailored Solutions for Home Projects - From Renovations to Cleanouts",
        body: <ResidentialDumpster/>,
    },
    commercialDumpsterRentals: {
        url: "https://www.portsmouthdumpsterrentals.com/commercial-dumpster-rentals/",
        metaData: {
            title: "Commercial Dumpster Rentals for Businesses | Portsmouth VA",
            description:
                "Portsmouth Dumpster Rental provides reliable solutions. Portsmouth’s leading commercial dumpster provider.",
        },
        h1: "Commercial Dumpster Rentals in Portsmouth, VA",
        subtitle:
            "Streamlined Waste Management for Portsmouth's Vibrant Business Community",
        body: <CommercialDumpster/>,
    },
    rollOffDumpsterRentals: {
        url: "https://www.portsmouthdumpsterrentals.com/roll-off-dumpster-rentals/",
        metaData: {
            title:
                "Roll-Off Dumpster Rental | Flexible Waste Solutions | Portsmouth VA",
            description:
                "Explore roll-off dumpster rentals from Portsmouth Dumpster Rental. Ideal for both homes and businesses in Portsmouth, VA.",
        },
        h1: "Roll-Off Dumpsters for Flexible Disposal in Portsmouth",
        subtitle: "The Go-To Choice for Versatile and Efficient Waste Disposal.",
        body: <RollOffDumpster/>,
    },
    wasteManagement: {
        url: "https://www.portsmouthdumpsterrentals.com/waste-management/",
        metaData: {
            title:
                "Eco-Friendly Dumpster Rental | Responsible Disposal | Portsmouth VA",
            description:
                "Choose a greener way to dispose of your waste. Portsmouth Dumpster Rental commits to sustainable practices, ensuring a brighter future for Portsmouth, VA.",
        },
        h1: "Sustainable Waste Management Solutions in Portsmouth",
        subtitle:
            "Committing to a Cleaner, Greener Portsmouth One Dumpster at a Time.",
        body: <WasteManagement/>,
    },
    sameDayDumpsterRental: {
        url: "https://www.portsmouthdumpsterrentals.com/same-day-dumpster-rentals/",
        metaData: {
            title:
                "Same-Day Dumpster Rental in Portsmouth VA | Quick & Reliable Service",
            description:
                "Need a dumpster today? Portsmouth Dumpster Rental offers same-day services in Portsmouth, VA. Fast, efficient, and reliable - perfect for your urgent disposal needs.",
        },
        h1: "Fast and Efficient Same-Day Dumpster Rentals in Portsmouth",
        subtitle:
            "Your Go-To Solution for Immediate Dumpster Needs in Portsmouth, VA.",
        body: <SameDayDumpster/>,
    },
    eventWasteManagement: {
        url: "https://www.portsmouthdumpsterrentals.com/event-waste-management/",
        metaData: {
            title: "Event Waste Management in Portsmouth VA | Clean & Efficient",
            description:
                "Ensure a clean and orderly event in Portsmouth, VA with our specialized waste management services. Perfect for festivals, corporate events, and private gatherings.",
        },
        h1: "Professional Event Waste Management Services in Portsmouth",
        subtitle: "Keeping Your Events in Portsmouth Clean and Green.",
        body: <EventWasteManagement/>,
    },
    junkRemovalServices: {
        url: "https://www.portsmouthdumpsterrentals.com/junk-removal-services/",
        metaData: {
            title:
                "Efficient Junk Removal Services in Portsmouth VA | Clutter-Free Solutions",
            description:
                "Reliable junk removal services. Quick, eco-friendly, and hassle-free - clearing the way for a cleaner space.",
        },
        h1: "Comprehensive Junk Removal Services in Portsmouth",
        subtitle:
            "Effortlessly Clearing Out Clutter from Your Portsmouth Property.",
        body: <JunkRemovalServices/>,
    },
    longTermDumpsterRental: {
        url: "https://www.portsmouthdumpsterrentals.com/long-term-dumpster-rentals/",
        metaData: {
            title:
                "Long-Term Dumpster Rentals in Portsmouth VA | Flexible & Reliable",
            description:
                "For extended projects in Portsmouth, VA, choose our long-term dumpster rentals. Flexible rental periods, reliable service, and consistent waste management support.",
        },
        h1: "Reliable Long-Term Dumpster Rental Services in Portsmouth",
        subtitle:
            "Your Partner in Sustained Waste Management for Long-Term Projects.",
        body: <LongTermDumpsterRentals/>,
    },
    404: {
        metaData: {
            title: "Page Not Found - Portsmouth Dumpster Rentals | (757) 704-5492",
            description:
                "The page you're seeking doesn't seem to exist. Portsmouth Dumpster Rentals is always here to help. Reach out for any assistance.",
            siteName: "Portsmouth Dumpster Rentals | (757) 704-5492",
        },
        h1: "404 - Page Not Found",
        subtitle: "Apologies, the page you're after isn't available.",
        p: "Whether it's residential, commercial, or construction dumpster rental, Portsmouth Dumpster Rentals has got you covered. Dial (757) 704-5492 anytime for expert assistance.",
    },
};
